import React, { Component } from "react"
import { loadableP5 as P5Wrapper } from "./Loadable"
// import Sketch from "./sketch"
// import SketchRedShine from "./SketchRedShine"
import SketchLayerCones from "./SketchLayerCones"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default class App extends Component {
  render() {
    return (
      <>
        <P5Wrapper sketch={SketchLayerCones} />
      </>
    )
  }
}
