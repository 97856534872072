import * as d3 from "d3"
export default function SketchZenNetwork(p5) {
  let fr = 60
  let colors = [
    "#ed3441",
    "#EAEAEA",
    "#329fe3",
    "#154296",
    "#ffffff",
    "#D0E7B0",
  ]

  let num_layer = 20
  let num_radiation = 48

  let noises = []
  let x = 0
  let y = 0
  let scl = 0.005
  let angle = 0

  let cycle_freq = 216
  let cycle_timing = 0

  let y_diff = 0
  let x_diff = 0

  let ca
  let cb
  p5.setup = () => {
    const calcCanvasSize = () => {
      const windowWidth = p5.windowWidth
      const windowHeight = p5.windowHeight
      let minDimension = d3.min([windowWidth, windowHeight])
      // let minDimension = windowHeight
      if (1500 <= minDimension) {
        // minDimension = minDimension * 0.9 * 0.6
        minDimension = minDimension * 0.8
      } else if ((676 <= minDimension) & (minDimension <= 1170)) {
        minDimension = minDimension * 0.8
      } else {
        minDimension = minDimension
      }
      return minDimension
    }
    const numberOfNode = 40

    // const c = p5.createCanvas(p5.displayWidth, p5.displayHeight, p5.WEBGL)
    const canvasWidth = calcCanvasSize()
    const c = p5.createCanvas(canvasWidth, canvasWidth * 0.9)
    p5.colorMode(p5.HSB, 100)
    p5.frameRate(fr)
    p5.colorMode(p5.RGB)
    ca = p5.color("#0CCBCFAA")
    cb = p5.color("#FE68B5AA")
    p5.background("#FFF")

    for (let j = 0; j < num_radiation * 10; j++) {
      for (let i = 0; i < num_layer * 10; i++) {
        angle = p5.noise(x * scl, y * scl, 0.02) * 100
        x += p5.cos(angle) * 2
        y += p5.sin(angle) * 2
        noises.push([x, y])
      }
    }
  }

  p5.draw = () => {
    // background(250);
    let radius = p5.width * 0.2
    let radius_rotate = p5.width * 0.02
    p5.translate(p5.width / 2, p5.height / 2)
    let noise_index = 0

    const angle_add = (cycle_timing / cycle_freq) * p5.PI
    for (let j = 0; j < num_layer; j++) {
      p5.strokeWeight(0.1)
      p5.stroke(p5.lerpColor(ca, cb, p5.abs(p5.sin(angle_add))))
      p5.beginShape(p5.LINES)
      for (let i = 0; i < num_radiation; i++) {
        let a = (i / num_radiation) * p5.PI

        switch (j % 4) {
          case 0:
            y_diff = p5.sin(2 * angle_add) * radius_rotate * i
            x_diff = p5.cos(2 * angle_add) * radius_rotate * i
            break
          case 1:
            y_diff = p5.sin(2 * angle_add) * radius_rotate * i
            x_diff = p5.cos(2 * angle_add) * radius_rotate * i
            break
          case 2:
            y_diff = p5.sin(2 * angle_add) * radius_rotate * i
            x_diff = p5.cos(2 * angle_add) * radius_rotate * i
            break
          default:
            y_diff = p5.sin(2 * angle_add) * radius_rotate * i
            x_diff = p5.cos(2 * angle_add) * radius_rotate * i
        }
        // vertex(sin(2 * a) * radius * i / num_layer + noises[noise_index][0] + y_diff, cos(2 * a) * radius * i / num_layer + noises[noise_index][1] + x_diff);
        // vertex(sin(2 * a) * radius * j / num_layer + noises[noise_index][0], cos(2 * a) * radius * j / num_layer + noises[noise_index][1]);
        // vertex(cos(2 * a) * radius * j / num_layer + noises[noise_index][0] + x_diff, sin(2 * a) * radius * j / num_layer + noises[noise_index][1] + y_diff);
        p5.vertex(
          (p5.cos(2 * a) * radius * j) / num_layer +
            noises[noise_index][0] +
            x_diff,
          (p5.sin(2 * a) * radius * j) / num_layer +
            noises[noise_index][1] +
            y_diff
        )

        // console.log(x_diff);
        // console.log(y_diff);

        noise_index = noise_index + 1
        // erase();
        // ellipse(100, 30, 30);
        // noErase()
      }
      p5.endShape()
    }

    cycle_timing = cycle_timing + 1

    if (cycle_timing == 1000) {
      p5.background("#FFF")
    }
  }
}
